import React from 'react';
import logo from './logo.svg';
import './App.css';
import JsZip from 'jszip';
import Promise from 'bluebird';
import FileSaver from 'file-saver';
import Airtable from 'airtable';
import Select from 'react-select';

const loadDataFromAirtable = (table, callback, filters, finishCallback) => {
  var base = new Airtable({ apiKey: 'keyo0ce0rDznIs3qk' }).base(
    'appcRatkPgTbTXvtp'
  );

  base(table)
    .select(filters)
    .eachPage(
      (records, fetchNextPage) => {
        records.forEach((record) => {
          callback(record);
        });
        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      },
      (err) => {
        if (err) {
          console.error(err);
        }

        if (finishCallback) {
          finishCallback(err);
        }
      }
    );
};

const download = async (url) => {
  const resp = await fetch(url);
  return resp.blob();
};

const downloadByGroup = (urls, files_per_group = 5, callback) => {
  let cptr = 0;
  return Promise.map(
    urls,
    async (url) => {
      const blob = await download(url.url);
      callback(`${++cptr} documents téléchargé sur ${urls.length}`);
      return { name: url.name, blob };
    },
    { concurrency: files_per_group }
  );
};

const exportZip = (blobs) => {
  const zip = JsZip();
  blobs.forEach((blob, i) => {
    zip.file(blob.name, blob.blob);
  });
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    const currentDate = new Date().getTime();
    const fileName = `Export-${currentDate}.zip`;
    return FileSaver.saveAs(zipFile, fileName);
  });
};

const downloadAndZip = (urls, callback) => {
  return downloadByGroup(urls, 5, callback).then(exportZip);
};

function App() {
  const [text, setText] = React.useState('Selectionnez un mois..');
  const [factureWarnings, setFactureWarnings] = React.useState([]);
  const [views, setViews] = React.useState([]);
  const [selectedView, setSelectedView] = React.useState(undefined);

  React.useEffect(() => {
    let array = [];
    loadDataFromAirtable(
      'Views',
      (record) => array.push({ id: record.id, label: record.get('Name') }),
      undefined,
      () => setViews(array)
    );
  }, []);

  const truc = async () => {
    if (!selectedView) {
      alert('Aucun mois selectionné');
      return;
    }

    let ddlUrls = [];
    let warnings = [];

    setFactureWarnings([]);

    loadDataFromAirtable(
      'Factures',
      (record) => {
        const facture = record.get('Facture');
        if (facture) {
          ddlUrls.push({
            name: facture[0].filename,
            url: facture[0].url
          });
        } else {
          warnings.push(record.get('Numéro de Facture'));
        }
      },
      {
        view: selectedView
      },
      (err) => {
        if (err) {
          alert('Erreur lors de la récupération: ' + err.message);
          return;
        }

        setText('Téléchargement en cours...');
        downloadAndZip(ddlUrls, setText).then(() => {
          setText('Téléchargement terminé !');
          setSelectedView(undefined);

          if (warnings.length > 0) {
            alert("Certaines factures n'ont pas pu être téléchargées");
            setText('Vérifiez les factures suivantes:');
            setFactureWarnings(warnings);
          }
        });
      }
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>{text}</p>
        {factureWarnings && factureWarnings.length > 0 && (
          <ul>
            {factureWarnings.map((facture) => (
              <li>{facture}</li>
            ))}
          </ul>
        )}
        <div className="select-container">
          <Select
            onChange={(elem) => setSelectedView(elem.label)}
            options={views}
            styles={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: 'black'
                };
              }
            }}
          />
        </div>
        {selectedView && (
          <button className="myButton" onClick={truc}>
            Télécharger
          </button>
        )}
      </header>
    </div>
  );
}

export default App;
